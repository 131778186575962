import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import useSession from '../hooks/useSession';
import { Link } from 'react-router-dom'
import authAPI from '../services/auth'

const Auth = () => {
  const [session, setSession] = useSession()
  const [error, setError] = useState([])
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const {
    email,
    password,
  } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value})
  const onSubmit = async (e) => {
    e.preventDefault()
    setError([]);
    try {
      const res = await authAPI.post({email, password})
      setSession(res.data.token);
      navigate('/projects#project');
    } catch (err) {
      setError(err.response.data.errors.map(e => <p>{e.msg}</p>))
    }
  }
  return (
    <section className="container">
      <h1 className="large text-primary">Sign In</h1>
      <p className="lead"><i className="fas fa-user"></i> Sign into Your Account</p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email Address"
            onChange={ e => onChange(e) }
            name="email"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            onChange={ e => onChange(e) }
            placeholder="Password"
            name="password"
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Login" />
      </form>
      { error.length > 0 ? <div className="alert alert-danger">
        {error.map(e => e)}
      </div> : false}
      <p className="my-1">
        Don't have an account? <Link to="/register">Sign Up</Link>
      </p>
    </section>
  )
}

export default Auth