import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import useSession from '../hooks/useSession';
import authAPI from '../services/auth';
import paymentAPI from '../services/payment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm'

const Payments = () => {
  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_test_edpI7JhPjvcfsRQc8j78GKuR'))
  const [session] = useSession()
  const [user, setUser] = useState(false)
  const [projectInfo, setProjectInfo] = useState(false)
  const [price, setPrice] = useState(0)
  const [options, setOptions] = useState(false)
  const navigate = useNavigate();
  const { data } = useParams();

  useEffect(() => {
    (async () =>{
      try {
        if (session && !user) {
          const res = await authAPI.get({jwt: session})
          setUser(res.data)

          const projectInfo = JSON.parse(atob(data))
          setProjectInfo(projectInfo)
          // setPaymentUrl(`/widget#project/posted/${projectInfo.hash_id_completed_form}/payment/${projectInfo.hash_id_bill}`)
          setPrice(projectInfo.total)
          const response = await paymentAPI.post({
            amount: projectInfo.total,
            description: '',
            customer: res.data.email,
            currency: res.data.currency || 'usd',
          }, session)
          console.log(response)
          setOptions(response.data)
        } else if(!user){
          navigate('/login')
        }
      } catch (error) {
        navigate('/login')
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <section className="container">
      {
        options ? (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              price={price}
              formId={projectInfo.hash_id_completed_form}
              billId={projectInfo.hash_id_bill}
            />
          </Elements>
        ) :
        (
          <div>...Loading</div>
        )
      }
    </section>
  )
}
export default Payments
