import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useSession from '../hooks/useSession';
import authAPI from '../services/auth';
const PaymentConfirmation = () => {
  const [session] = useSession()
  const [user, setUser] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    (async () =>{
      try {
        if (session && !user) {
          const res = await authAPI.get({jwt: session})
          setUser(res.data)
        } else if(!user){
          navigate('/login')
        }
      } catch (error) {
        navigate('/login')
      }
    })()
  }, [])
  return (
    <section className="container" id="project">
      <h1>Payment made</h1>
    </section>
  )
}

export default PaymentConfirmation
