import { useState, useCallback } from 'react'

const useSession = (jwtParam = '') => {
  const [state, setState] = useState('');
  let tempJWT = '';
  if (!jwtParam && !state) {
    tempJWT = localStorage.getItem('bunny.JWT')
    if (tempJWT){
      setState(tempJWT)
    }
  }
  const setSession = useCallback((jwt) => {
    setState(jwt)
    localStorage.setItem('bunny.JWT', jwt)
  }, [])
  return [state, setSession]
}
export default useSession
