import axios from 'axios'

const post = (payload) => {
  const config = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
  }
  return axios.post('/api/users', payload, config)
}
const userAPI = { post }
export default  userAPI