import axios from 'axios'

const post = (payload, jwt) => {
  const config = {
    headers: {
        'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'x-auth-token': jwt
    }
  }
  console.log('hola');
  return axios.post('/api/payments', payload, config)
}
const paymentsApi = { post }
export default  paymentsApi