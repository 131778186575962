import './App.css';
import NavBar from './components/layout/NavBar';
import Landing from './components/layout/Landing';
import Register from './components/Register';
import Auth from './components/Auth';
import Widget from './components/Widget'
import Payment from './components/Payment'
import PaymentConfirmation from './components/PaymentConfirmation'
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'

const App = () => {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={ <Landing />} />
          <Route exact path="/register/" element={ <Register />} />
          <Route exact path="/login/" element={ <Auth />} />
          <Route exact path="/widget/" element={ <Widget />} />
          <Route exact path="/payments/:data/" element={ <Payment />} />
          <Route exact path="/payments-confirmation/" element={ <PaymentConfirmation />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
