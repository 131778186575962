import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import useSession from '../hooks/useSession';
import userAPI from '../services/user'

const Register = () => {
  // eslint-disable-next-line no-unused-vars
  const [session, setSession] = useSession()
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: ''
  });
  const [error, setError] = useState([]);

  const {
    name,
    email,
    password,
    password2,
  } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value})
  const onSubmit = async (e) => {
    e.preventDefault()
    setError([]);
    if (password !== password2) {
      setError([<p>Passwords do not match</p>])
    } else {
      try {
        const res = await userAPI.post({name, email, password})
        setSession(res.data.token);
        navigate('/widget#project');
      } catch (err) {
        setError(err.response.data.errors.map(e => <p>{e.msg}</p>))
      }
    }
  }
  return (
    <section className="container">
      <h1 className="large text-primary">Sign Up</h1>
      <p className="lead"><i className="fas fa-user"></i> Create Your Account</p>
      <form className="form" onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={ e => onChange(e) }
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={ e => onChange(e) }
            name="email"
          />
          <small className="form-text"
            >This site uses Gravatar so if you want a profile image, use a
            Gravatar email</small
          >
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={ e => onChange(e) }
            minLength="6"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Confirm Password"
            name="password2"
            value={password2}
            onChange={ e => onChange(e) }
            minLength="6"
          />
        </div>
        <input type="submit" className="btn btn-primary" value="Register" />
      </form>
      <p className="my-1">
        Already have an account? <Link to="/login">Sign In</Link>
      </p>
      { error.length > 0 ? <div className="alert alert-danger">
        {error.map(e => e) }
      </div> : false}
    </section>
  )
}

export default Register