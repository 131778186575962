import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useSession from '../hooks/useSession';
import authAPI from '../services/auth';
const Widget = () => {
  const [session] = useSession()
  const [user, setUser] = useState(false)
  const navigate = useNavigate();
  const eventHandler = (ev) => {
    // error_hash: "#project/posted/2Gu1hY3hBXQ8YO/payment/1EyB8Z1JpVGvfq/error"
    // hash_id_bill: "1EyB8Z1JpVGvfq"
    // hash_id_completed_form: "2Gu1hY3hBXQ8YO"
    // success_hash: "#project/posted/2Gu1hY3hBXQ8YO/payment/1EyB8Z1JpVGvfq/success"
    // total: 220.2
    const data = {
      hash_id_completed_form: ev.detail.data.hash_id_completed_form,
      hash_id_bill: ev.detail.data.hash_id_bill,
      total: ev.detail.data.total,
    }
    console.log("data", data)
    const slug = btoa(JSON.stringify(data))
    navigate(`/payments/${slug}`)
    console.log(slug)
  }
  useEffect(() => {
    (async () =>{
      try {
        if (session && !user) {
          const res = await authAPI.get({jwt: session})
          setUser(res.data)
        } else if(!user){
          navigate('/login')
        }
        document.getElementById('widget').addEventListener('bsSubmit',eventHandler)
      } catch (error) {
        navigate('/login')
      }
    })()
    return () => {
      // document.getElementById('widget').removeEventListener('bsSubmit', eventHandler)
    }
  }, [])
  return (
    <section className="container" id="project">
      <h1>Projects</h1>
      {user ? <be-widget
        id="widget"
        key-value={user.key}
        client-id={user.email}
      /> : null }
    </section>
  )
}

export default Widget
