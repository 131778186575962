import axios from 'axios'

const post = (payload) => {
  const config = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
  }
  return axios.post('/api/auth', payload, config)
}

const get = ({jwt}) => {
    const config = {
      headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'x-auth-token': jwt
      }
    }
    return axios.get('/api/auth', config)
  }
const authAPI = { post, get }
export default authAPI