import useSession from '../../hooks/useSession';
import { Link } from 'react-router-dom'

const NavBar = () => {
  const [session] = useSession()
  return (
    <nav className="navbar bg-dark">
      <div className='home'>
        <Link to="/"><i className="fas fa-code"></i>BunnyWidget</Link>
      </div>
      <ul>
        {session ? (<li><Link to="/widget#project">Create a project</Link></li>) :
          (<>
            <li><Link to="/register">Register</Link></li>
            <li><Link to="/login">Login</Link></li>
          </>)
        }
      </ul>
    </nav>
  )
}

export default NavBar